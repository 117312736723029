import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentTypeFieldset } from '@parashift/shared/models';
import { DocumentTypeFieldsetType } from '@parashift/shared/types';
import { ServiceDecorator } from './decorators';
import { BaseApiService } from './base';
import { QueryParamsService } from '../query-params.service';
import { Endpoint } from '../env.service';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => DocumentTypeFieldset,
  endpointUrl: DocumentTypeFieldsetType
})
export class DocumentTypeFieldsetService extends BaseApiService<DocumentTypeFieldset> {
  className = DocumentTypeFieldsetService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version
  }

  inheritFrom(document_type_fieldset_id: string, tenant_id: number, changedAttributes: { [name: string]: any } = {}): Observable<DocumentTypeFieldset> {
    const new_document_type_fieldset = new DocumentTypeFieldset(changedAttributes);

    const urlVars = {
      postfix: 'inherit_from/:document_type_fieldset_id',
      document_type_fieldset_id
    };

    return this.saveRecord(new_document_type_fieldset, tenant_id, urlVars);
  }
}
